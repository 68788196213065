import React from "react";
import { Component } from "react";

import { lang } from './Lang';
import { AppCard } from './common/AppCard';
import { getDefaultServerUrl } from './LinkBuilder';
import { SwitchProperties } from "./edit/SwitchProperties";

export class Panel extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
            appList:[],
            openNewAppForm: false,
            value:'',
            appNameFieldEmptyErr: false,
            openRightContainer: 'content', //'content-reduced'
            editionMode: false,
            propertiesType:'',
            editionCardInstance: null,
            editionCardJson: {}
        };
    }

    componentDidMount() {
        
        this.getAllApps(false);
    }

    getAllApps(isEditionMode) {

        var urlCustId = 'custId=' + localStorage.getItem('custId');
        var isEditionModeUrl = 'isEditionMode=' + isEditionMode;
        var serverURL = getDefaultServerUrl('v1', 'getAllApps', [], [urlCustId, isEditionModeUrl]);

        fetch(serverURL)
        .then(res => res.json())
        .then(
            (result) => {

                this.setState({
                    appList: result
                });
            },
            (error) => {
                this.setState({
                    error
                });
            }
        );
    }

    goHome(){
        window.open(window.location.origin, '_self');
    }

    addNewFolder(event){

    }

    closeFieldsMenu(){

        this.setState({
            openNewAppForm:false
        });
    }

    handleChange(event) {
        this.setState({
            value: event.target.value
        });
    }

    addNewApp(event){

        //Add generic app to first folder
        let custIdUrl = 'custId=' + localStorage.getItem('custId');
        let appNameUrl = 'appName=' + lang('new.app.unnamed.lb');
        let serverURL = getDefaultServerUrl('v1', 'putApp', [], [custIdUrl, appNameUrl]);

        fetch(serverURL)
        .then(res => res.json())
        .then(
            (result) => {

                this.setState({
                    editionMode: true,
                    appList: this.state.appList.concat(result)
                });

                //open edit properties
                this.openPropertiesMode('AppCardProperties', this.state.editionCardInstance, this.state.editionCardJson);
            },
            (error) => {
                console.log("put new app error");
            }
        );
    }

    //This method is to synchronize last app added by newApp button 
    setEditionCardInstance(appCardInstance, editionCardJson){

        this.setState({
            editionCardInstance: appCardInstance,
            editionCardJson: editionCardJson
        });
    }

    changeToEditionMode() {
        this.setState({
            editionMode: true
        });

        this.getAllApps(true);
    }

    changeToExecutionMode() {
        this.setState({
            editionMode: false,
            openRightContainer: 'content'
        });

        this.getAllApps(false);
    }

    async openPropertiesMode(propertiesType, editionCardInstance, editionCardJson){

        await this.closePropertiesMode();

        this.setState({
            propertiesType: propertiesType,
            editionCardInstance: editionCardInstance,
            editionCardJson: editionCardJson,
            openRightContainer: 'content-reduced'
        });
    }

    async closePropertiesMode(){
        this.setState({
            openRightContainer: 'content'
        });
    }


    render() {

        return (
            <section className='height-max'>

                <div className='left-bar'>

                    <section>

                        <button className='logo' onClick={this.goHome.bind(this)}/>
                        <span className='logo-name'>{lang('trade.mark')}</span>

                    </section>

                    <section>

                        <div className='left-bar-bt-container'>
                            <button className='app-folder-new-bt' onClick={this.addNewFolder.bind(this)}>{lang('panel.newFolder.bt')}</button>
                        </div>

                    </section>

                </div>

                <div className='top-bar'>
                    
                    <button className='app-card-new-project-bt' onClick={this.addNewApp.bind(this)}>novo formulário</button>
                    
                    <div>

                        <button className='user-menu-open-icon'/>
                        <button className='user-menu-icon'/>

                    </div>

                    {/*TODO: Just render mode buttons to authorized users*/}                    
                    <button className={!this.state.editionMode ? 'execution-mode-bt active-mode' : 'execution-mode-bt'} onClick={this.changeToExecutionMode.bind(this)}>execução</button>
                    <button className={this.state.editionMode ? 'edition-mode-bt active-mode' : 'edition-mode-bt'} onClick={this.changeToEditionMode.bind(this)}>edição</button>
                
                </div>
                
                <div className='content-container'>

                    <div className='breadcrump'>empresa x &gt; <span className="breadcrump-actual">painel</span></div>
                    
                    <div className={this.state.openRightContainer}>

                        <div className='panel-apps-container'>

                            <div className='panel-apps-title'>{lang('panel.myApps.lb')}</div>

                            <div className='panel-apps-scroll'>
                                {this.state.appList.map(appJson => (
                                    <AppCard key={appJson._id} json={appJson} editionMode={this.state.editionMode} panel={this}/>
                                ))}
                            </div>

                        </div> 

                    </div>
                    
                    {this.state.openRightContainer === 'content-reduced' &&

                        <div className='right-container'>

                            <div className="properties-top-bar">
                                <button className="close-properties-bt" onClick={this.closePropertiesMode.bind(this)}/>
                            </div>

                            <SwitchProperties type={this.state.propertiesType} appCard={this.state.editionCardInstance} json={this.state.editionCardJson}/>

                        </div>
                    }

                </div>

                <div className='bottom-bar'></div>

            </section>
        );
    }
}