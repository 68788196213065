import React from "react";
import { Component } from "react";

export class ColorPicker extends Component{

    constructor(props) {
        super(props);

        this.state = {
            colors: ['#B80000', '#DB3E00', '#FCCB00', '#008B02', '#006B76', '#1273DE', '#004DCF', '#EB9694', '#FAD0C3', '#FEF3BD', '#C1E1C5', '#BEDADC', '#C4DEF6', '#BED3F3']
        };
    }

    clickColor(color){
        this.props.onSelectColor(color);
    }

    render(){
        return (
            <section>
                <div className='color-picker-container'>
                    {this.state.colors.map(color => (
                        <button key={color} className='color-picker-bt' style={{backgroundColor:color}} onClick={this.clickColor.bind(this,color)}/>
                    ))}
                </div>
            </section>
        );
    }
}