import React from "react";
import { Component } from "react";
import { lang } from '../Lang'
import { PopupDialog } from '../common/PopupDialog';
import { getDefaultServerUrl } from '../LinkBuilder';
import { PopupTemp } from "../common/PopupTemp";
import { SuccessMessageInside } from "../common/SuccessMessageInside";

export class FieldOptsBTEdit extends Component {

    constructor(props){
        super(props);

        this.state = {
            optOpen:false,
            maxCharacters: 256,
            diasableMaxCharactersField: false,
            maxCharacterError: false,
            apiFieldName: '',
            apiFieldNameError: false,
            saveSuccess: false
        };
    }

    componentDidMount() {

        if(this.props.json.maxCharacters){

            this.setState({
                maxCharacters: this.props.json.maxCharacters
            });  
        }

        if(this.props.json.apiFieldName){

            this.setState({
                apiFieldName: this.props.json.apiFieldName
            });  
        }

        if(this.props.json.relationFormOpt){

            this.setState({
                diasableMaxCharactersField: true
            });
        }
    }

    handleOptMenu(event){

        if(this.state.optOpen){
            
            this.setState({
                optOpen:false
            });

        }else{

            this.setState({
                optOpen:true
            });
        }
    }

    closePopupDialog(){

        this.setState({
            optOpen:false
        });
    }

    closePopupTimer(){

        this.setState({
            optOpen:false,
            saveSuccess: false
        });
    }

    handleChangeMaxCharacters(event){
        this.setState({
            maxCharacters: event.target.value
        });
    }

    handleApiFieldName(event){
        this.setState({
            apiFieldName: event.target.value
        });
    }

    updateForm(event) {

        if(this.state.maxCharacters < 1
            || this.state.maxCharacters > 256){

            this.setState({
                maxCharacterError: true
            });

            return;

        }else{
            this.setState({
                maxCharacterError: false
            });
        }

        if(this.state.apiFieldName.trim() === ''){

            this.setState({
                apiFieldNameError: true
            });

            return;

        }else{
            this.setState({
                apiFieldNameError: false
            });
        }

        let fieldIdUrl = 'fieldId='+this.props.json._id;
        let maxCharactersUrl = 'maxCharacters=' + this.state.maxCharacters;
        let apiFieldNameUrl = 'apiFieldName=' + this.state.apiFieldName;
        let serverURL = getDefaultServerUrl('v1', 'updateFieldOpts', [], [fieldIdUrl, maxCharactersUrl, apiFieldNameUrl]);

        fetch(serverURL)
            .then(res => res.json())
            .then(
                (result) => {

                    this.setState({
                        saveSuccess: true
                    });

                    console.log("Field Opts update ok");
                },
                (error) => {
                    console.log("Field Opts update error");
                }
            );
    }

    render() {

        return (
            <section>
                <button className='field-opt-bt' title={lang('fieldOptsBTEdit.edit.title')} onClick={this.handleOptMenu.bind(this)}/>
                
                {this.state.optOpen &&
                    <PopupDialog parent={this} width='50%' title='+configurações de campo'>

                        <section>

                            <div>

                                <div className='field-popup-container-lb'>
                                    <label className='field-name'>número máximo de caracteres:</label>
                                </div>

                                <div className='field-popup-container-input'>
                                    <input type='number' className={this.state.maxCharacterError ? 'field-popup-input-small input-error' : 'field-popup-input-small'} value={this.state.maxCharacters} onChange={this.handleChangeMaxCharacters.bind(this)} readOnly={this.state.diasableMaxCharactersField}/>
                                </div>

                                <div className={this.state.maxCharacterError ? 'field-popup-input-help field-error' : 'field-popup-input-help'}>{this.state.diasableMaxCharactersField ? 'campos de relacionamento não usam esse atributo' : 'mínimo 1 e máximo 256'}</div>

                            </div>

                            <div className='popup-dialog-content-sub-container'>configuração de api [opcional]</div>

                            <div>

                                <div className='field-popup-container-lb'>
                                    <label className='field-name'>nome do campo para integração:</label>
                                </div>

                                <div className='field-popup-container-input'>
                                    <input type='text' className={this.state.apiFieldNameError ? 'field-popup-input-small input-error' : 'field-popup-input-small'} value={this.state.apiFieldName} onChange={this.handleApiFieldName.bind(this)}/>
                                </div>

                                <div className={this.state.apiFieldNameError ? 'field-popup-input-help field-error' : 'field-popup-input-help'}>use um nome curto sem espaços e sem caracteres especiais. ex.: nomePessoa</div>

                            </div>

                            <button className='forms-relationship-do-relation-bt' onClick={this.updateForm.bind(this)}>salvar</button>

                        </section>

                    </PopupDialog>
                }

                {this.state.saveSuccess &&
                    <PopupTemp parent={this} width='30%' title='Sucesso' milesecondsTime='2000'>
                        <SuccessMessageInside isToShow='true' msg='dados salvos com sucesso!'/>
                    </PopupTemp>
                }

            </section>
        );
    }
}