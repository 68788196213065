import React from "react";
import { Component } from "react";

export class PopupDialog extends Component{

    constructor(props){
        super(props);

        this.state = {
            popupSize:{width:this.props.width}
        };
    }


    closePopup(){
        this.props.parent.closePopupDialog();
    }

    render(){

        return (
            <section className='popup-dialog-background'>
                <div className='popup-dialog-content-container' style={this.state.popupSize}>
                    <div className='popup-dialog-title'>{this.props.title}</div>
                    <button className='popup-dialog-close-bt' onClick={this.closePopup.bind(this)}></button>
                    <div className='popup-dialog-content'>
                        {this.props.children}
                    </div>
                </div>
            </section>
        );
    }
}