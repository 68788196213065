import React from "react";
import { Component } from "react";
import { FormStackRun } from "./run/FormStackRun";

const formId = window.location.pathname.replace('/', '');

export class EngineRun extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
            formStack:[{_id:formId, isNewFormStack:false, cssClass:'form-stack-container-show'}]
        };
    }

    addFormStack(formStackId, srcComponentObj, oringfieldsIdsToTitle){

        //Hide others forms
        for (var i = 0; i < this.state.formStack.length; i++) {
            
            var form = this.state.formStack[i];
            
            form.cssClass = 'form-stack-container-hidden';
        }

        this.state.formStack.push({_id:formStackId, isNewFormStack:true, relatedObj:srcComponentObj, fieldsIdsToTitle:oringfieldsIdsToTitle, cssClass:'form-stack-container-show'});

        this.setState({
            formStack: this.state.formStack
        });
    }

    backToLastForm(){

        //Remove the last form from stack
        this.state.formStack.pop();

        this.setState({
            formStack: this.state.formStack
        });

        var form = this.state.formStack[this.state.formStack.length -1];
        form.cssClass = 'form-stack-container-show';
    }

    render() {

        return (
            <section>
                <h1>Cuboform</h1>
                <div className='form-container'>
                    {this.state.formStack.map(form => (
                        <div key={form._id} className={form.cssClass}>
                            <FormStackRun key={form._id} engineRun={this} stackFormId={form._id} isNewFormStack={form.isNewFormStack} relatedObj={form.relatedObj} fieldsIdsToTitle={form.fieldsIdsToTitle}/>
                        </div>
                    ))}
                </div>
            </section>
        );
    }
}