export function getDefaultServerUrl(apiVersion, service, pathNameArray, paramArray) {

    var serverURL = "";

    if(window.location.hostname === "app.cubofy.com"){

        serverURL = "https://us-central1-cubofyprod.cloudfunctions.net/webApi/" + apiVersion + "/" + service;

    }else{

        serverURL = "http://localhost:5001/cubofyprod/us-central1/webApi/" + apiVersion + "/" + service;
    }

    for (let i = 0; i < pathNameArray.length; i++) {
        
        let element = pathNameArray[i];
        
        serverURL += element;
    }

    let paramConector = '?';
    for (let i = 0; i < paramArray.length; i++) {
        
        let element = paramArray[i];
        
        if(i > 0){
            paramConector = '&';
        }

        serverURL += paramConector + element;
    }

    return serverURL;
}

export function getDefaultViewUrl(pathNameArray, paramArray) {

    var serverURL=  window.location.protocol
                        + "//"
                        + window.location.hostname
                        + ":" + window.location.port;

    for (let i = 0; i < pathNameArray.length; i++) {
        
        let element = pathNameArray[i];
        
        serverURL += element;
    }

    let paramConector = '?';
    for (let i = 0; i < paramArray.length; i++) {
        
        let element = paramArray[i];
        
        if(i > 0){
            paramConector = '&';
        }

        serverURL += paramConector + element;
    }

    return serverURL;
}