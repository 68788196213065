import React from "react";
import { Component } from "react";
import { AppCardProperties } from './AppCardProperties';

export class SwitchProperties extends Component {

    render() {
        switch (this.props.type) {
            case 'AppCardProperties':
                return <AppCardProperties appCard={this.props.appCard} json={this.props.json}/>

            default:
                return <div>No recognized properties</div>
        }
    }
}