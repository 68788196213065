import React from "react";
import { Component } from "react";

export class PopupTemp extends Component{

    constructor(props){
        super(props);

        this.state = {
            popupSize:{width:this.props.width}
        };

        setTimeout(
            function(){ 
                this.props.parent.closePopupTimer();
            }.bind(this), this.props.milesecondsTime);
    }

    render(){

        return (
            <section className='popup-dialog-background'>
                <div className='popup-temp-content-container' style={this.state.popupSize}>
                    <div className='popup-temp-content'>
                        {this.props.children}
                    </div>
                </div>
            </section>
        );
    }
}