export function lang(key, wilcard) {

    var prefLang = navigator.language.toLowerCase();

    if(localStorage.getItem('prefLang') != null){
        prefLang = localStorage.getItem('prefLang');
    }

    if(prefLang.startsWith('en')){
        return langEnUS(key, wilcard);
    }else if(prefLang.startsWith('pt')){
        return langPtBR(key, wilcard);
    }else if(prefLang.startsWith('es')){
        return langEsES(key, wilcard);
    }else if(prefLang.startsWith('fr')){
        return langFrFR(key, wilcard);
    }else{
        return langEnUS(key, wilcard);
    }
}

function langWilcard(text, wilcard){
    return text.replace('@?', wilcard);
}

function langEnUS(key, wilcard){

    switch(key){
        case 'trade.mark':
            return langWilcard('cubofy', wilcard);
        case 'engine.save.bt':
            return langWilcard('save', wilcard);
        case 'engine.remove.bt':
            return langWilcard('delete', wilcard);
        case 'engine.clear.bt':
            return langWilcard('clear', wilcard);
        case 'tablerow.edit.bt':
            return langWilcard('edit', wilcard);
        case 'titleField.edit.placeholder':
            return langWilcard("inform field's name", wilcard); 
        case 'inputTextShort.edit.placeholder':
            return langWilcard("explain how the field must be filled", wilcard);
        case 'mandatoryField.edit.bt':
            return langWilcard("mandatory", wilcard);
        case 'mandatoryField.edit.title':
            return langWilcard("change to mandatory field", wilcard);
        case 'showInTable.edit.bt':
            return langWilcard("show in grid", wilcard);
        case 'showInTable.edit.title':
            return langWilcard("show the field on result grid", wilcard);
        case 'fieldOptsBTEdit.edit.title':
            return langWilcard("options", wilcard);
        case 'dragDropFieldBT.edit.title':
            return langWilcard("move", wilcard);
        case 'formRelationOneOpt.edit.placeholder':
            return langWilcard("inform the fill instruction", wilcard);
        case 'formRelationOneOpt.open.bt':
            return langWilcard("open form", wilcard);
        case 'formRelationOneOpt.edit.bt':
            return langWilcard("choose form", wilcard);
        case 'formRelationOneOpt.screenNoName.lb':
            return langWilcard("unnamed form", wilcard);
        case 'formRelationOneOpt.relationShipForms.title.lb':
            return langWilcard("forms to list", wilcard);
        case 'formatSession.name':
            return langWilcard("layout", wilcard);
        case 'formatOneColumn.name':
            return langWilcard("one column", wilcard);
        case 'formatTwoColumns.name':
            return langWilcard("two columns", wilcard);
        case 'formatMobile.name':
            return langWilcard("mobile", wilcard);
        case 'formTitle.edit.placeholder':
            return langWilcard("form name", wilcard);
        case 'formAddField.edit.bt':
            return langWilcard("add new field", wilcard);
        case 'formNoId.edit.lb':
            return langWilcard("link does not contain form id", wilcard);
        case 'engine.useItem.bt':
            return langWilcard("use seleted item", wilcard);
        case 'panel.app.session':
            return langWilcard("app", wilcard);
        case 'panel.appName.lb':
            return langWilcard("new app name", wilcard);
        case 'panel.appName.placeholder':
            return langWilcard("short and objective name", wilcard);
        case 'panel.appName.title':
            return langWilcard("use a short and objective name. eg sales, stock, budget", wilcard);
        case 'panel.newAppCreate.bt':
            return langWilcard("create", wilcard);
        case 'panel.newApp.bt':
            return langWilcard("create new", wilcard);
        case 'panel.newFolder.bt':
            return langWilcard("create folder", wilcard);
        case 'panel.myApps.lb':
            return langWilcard("my apps", wilcard);
        case 'props.app.title.lb':
            return langWilcard("name", wilcard);
        case 'props.app.color.lb':
            return langWilcard("color", wilcard);
        case 'props.app.icon.lb':
            return langWilcard("icon", wilcard);
        case 'props.app.show.panel.lb':
            return langWilcard("show on run mode", wilcard);
        case 'props.app.search.icon.placeholder':
            return langWilcard("icon name", wilcard);
        case 'props.general.mandatory.field':
            return langWilcard("mandatory field", wilcard);
        case 'new.app.unnamed.lb':
            return langWilcard("unnamed form", wilcard);
        case 'app.edit.form.fields.bt':
            return langWilcard("edit form fields", wilcard);
        default: 
            return 'not recgnized key language';
    }
}

function langPtBR(key, wilcard){

    switch(key){
        case 'trade.mark':
            return langWilcard('cubofy', wilcard);
        case 'engine.save.bt':
            return langWilcard('salvar', wilcard);
        case 'engine.remove.bt':
            return langWilcard('remover', wilcard);
        case 'engine.clear.bt':
            return langWilcard('limpar', wilcard);
        case 'tablerow.edit.bt':
            return langWilcard('editar', wilcard);
        case 'titleField.edit.placeholder':
            return langWilcard("digite o nome do campo", wilcard);
        case 'inputTextShort.edit.placeholder':
            return langWilcard("explique como o campo deve ser preenchido", wilcard);
        case 'mandatoryField.edit.bt':
            return langWilcard("obrigatório", wilcard);
        case 'mandatoryField.edit.title':
            return langWilcard("tornar o campo obrigatório", wilcard);
        case 'showInTable.edit.bt':
            return langWilcard("mostrar na tabela", wilcard);
        case 'showInTable.edit.title':
            return langWilcard("mostrar o campo na tabela de resultados", wilcard);
        case 'fieldOptsBTEdit.edit.title':
            return langWilcard("opções", wilcard);
        case 'dragDropFieldBT.edit.title':
            return langWilcard("mover", wilcard);
        case 'formRelationOneOpt.edit.placeholder':
            return langWilcard("digite a instrução de preenchimento", wilcard);
        case 'formRelationOneOpt.open.bt':
            return langWilcard("abrir formulário", wilcard);
        case 'formRelationOneOpt.edit.bt':
            return langWilcard("escolher formulário", wilcard);
        case 'formRelationOneOpt.screenNoName.lb':
            return langWilcard("formulário sem nome", wilcard);
        case 'formRelationOneOpt.relationShipForms.title.lb':
            return langWilcard("formulários para relacionar", wilcard);
        case 'formatSession.name':
            return langWilcard("formato", wilcard);
        case 'formatOneColumn.name':
            return langWilcard("uma coluna", wilcard);
        case 'formatTwoColumns.name':
            return langWilcard("duas colunas", wilcard);
        case 'formatMobile.name':
            return langWilcard("celular", wilcard);
        case 'formTitle.edit.placeholder':
            return langWilcard("nome do formulário", wilcard);
        case 'formAddField.edit.bt':
            return langWilcard("adicionar mais um campo", wilcard);
        case 'formNoId.edit.lb':
            return langWilcard("o link não contém o id do formulário", wilcard);
        case 'engine.useItem.bt':
            return langWilcard("usar item selecionado", wilcard);
        case 'panel.app.session':
            return langWilcard("aplicativo", wilcard);
        case 'panel.appName.lb':
            return langWilcard("nome do novo aplicativo", wilcard);
        case 'panel.appName.placeholder':
            return langWilcard("nome curto e objetivo", wilcard);
        case 'panel.appName.title':
            return langWilcard("use um nome curto e objetivo. ex.: vendas, estoque, orçamento", wilcard);
        case 'panel.newAppCreate.bt':
            return langWilcard("criar", wilcard);
        case 'panel.newApp.bt':
            return langWilcard("criar novo", wilcard);
        case 'panel.newFolder.bt':
            return langWilcard("criar pasta", wilcard);
        case 'panel.myApps.lb':
            return langWilcard("meus aplicativos", wilcard);
        case 'props.app.title.lb':
            return langWilcard("nome", wilcard);
        case 'props.app.color.lb':
            return langWilcard("cor", wilcard);
        case 'props.app.icon.lb':
            return langWilcard("ícone", wilcard);
        case 'props.app.show.panel.lb':
            return langWilcard("mostrar no modo execução", wilcard);
        case 'props.app.search.icon.placeholder':
            return langWilcard("nome do ícone (inglês)", wilcard);
        case 'props.general.mandatory.field':
            return langWilcard("campo obrigatório", wilcard);
        case 'new.app.unnamed.lb':
            return langWilcard("sem nome", wilcard);
        case 'app.edit.form.fields.bt':
            return langWilcard("editar campos", wilcard);
        default:
            return 'not recgnized key language';
    }
}

function langEsES(key, wilcard){

    switch(key){
        case 'trade.mark':
            return langWilcard('cubofy', wilcard);
        case 'engine.save.bt':
            return langWilcard('guardar', wilcard);
        case 'engine.remove.bt':
            return langWilcard('eliminar', wilcard);
        case 'engine.clear.bt':
            return langWilcard('limpia', wilcard);
        case 'tablerow.edit.bt':
            return langWilcard('editar', wilcard);
        case 'titleField.edit.placeholder':
            return langWilcard("informar el nombre del campo", wilcard);
        case 'inputTextShort.edit.placeholder':
            return langWilcard("explicar cómo se debe llenar el campo", wilcard);
        case 'mandatoryField.edit.bt':
            return langWilcard("obligatorio", wilcard);
        case 'mandatoryField.edit.title':
            return langWilcard("cambiar a campo obligatorio", wilcard);
        case 'showInTable.edit.bt':
            return langWilcard("mostrar en cuadrícula", wilcard);
        case 'showInTable.edit.title':
            return langWilcard("mostrar el campo en la cuadrícula de resultados", wilcard);
        case 'fieldOptsBTEdit.edit.title':
            return langWilcard("opciones", wilcard);
        case 'dragDropFieldBT.edit.title':
            return langWilcard("cambiar de posición", wilcard);
        case 'formRelationOneOpt.edit.placeholder':
            return langWilcard("informar la instrucción de llenado", wilcard);
        case 'formRelationOneOpt.open.bt':
            return langWilcard("abrir formulario", wilcard);
        case 'formRelationOneOpt.edit.bt':
            return langWilcard("elegir formulario", wilcard);
        case 'formRelationOneOpt.screenNoName.lb':
            return langWilcard("formulario sin nombre", wilcard);
        case 'formRelationOneOpt.relationShipForms.title.lb':
            return langWilcard("formularios para listar", wilcard);
        case 'formatSession.name':
                return langWilcard("diseño", wilcard);
        case 'formatOneColumn.name':
            return langWilcard("una columna", wilcard);
        case 'formatTwoColumns.name':
            return langWilcard("dos columnas", wilcard);
        case 'formatMobile.name':
            return langWilcard("móvil", wilcard);
        case 'formTitle.edit.placeholder':
            return langWilcard("nombre del formulario", wilcard);
        case 'formAddField.edit.bt':
            return langWilcard("agregar un campo más", wilcard);
        case 'formNoId.edit.lb':
            return langWilcard("el link no contiene lo id del formulario", wilcard);
        case 'engine.useItem.bt':
            return langWilcard("usar el elemento seleccionado", wilcard);
        case 'panel.app.session':
            return langWilcard("aplicación", wilcard);
        case 'panel.appName.lb':
            return langWilcard("nombre de la nueva aplicación", wilcard);
        case 'panel.appName.placeholder':
            return langWilcard("un nombre corto y objetivo", wilcard);
        case 'panel.appName.title':
            return langWilcard("use un nombre corto y objetivo. por ejemplo, ventas, stock, presupuesto", wilcard);
        case 'panel.newAppCreate.bt':
            return langWilcard("crear", wilcard);
        case 'panel.newApp.bt':
            return langWilcard("crear nuevo", wilcard);
        case 'panel.newFolder.bt':
            return langWilcard("crear carpeta", wilcard);
        case 'panel.myApps.lb':
            return langWilcard("mis aplicaciones", wilcard);
        case 'props.app.title.lb':
            return langWilcard("nombre", wilcard);
        case 'props.app.color.lb':
            return langWilcard("color", wilcard);
        case 'props.app.icon.lb':
            return langWilcard("icono", wilcard);
        case 'props.app.show.panel.lb':
            return langWilcard("mostrar no modo ejecución", wilcard);
        case 'props.app.search.icon.placeholder':
            return langWilcard("nombre del icono (inglés)", wilcard);
        case 'props.general.mandatory.field':
            return langWilcard("campo obligatorio", wilcard);
        case 'new.app.unnamed.lb':
            return langWilcard("sin nombre", wilcard);
        case 'app.edit.form.fields.bt':
            return langWilcard("editar campos", wilcard);
        default:
            return 'not recgnized key language';
    }
}

function langFrFR(key, wilcard){

    switch(key){
        case 'trade.mark':
            return langWilcard('cubofy', wilcard);
        case 'engine.save.bt':
            return langWilcard('enregistrer', wilcard);
        case 'engine.remove.bt':
            return langWilcard('supprimer', wilcard);
        case 'engine.clear.bt':
            return langWilcard('effacer', wilcard);
        case 'tablerow.edit.bt':
            return langWilcard('modifier', wilcard);
        case 'titleField.edit.placeholder':
            return langWilcard("informer le nom du champ", wilcard);
        case 'inputTextShort.edit.placeholder':
            return langWilcard("expliquer comment le champ doit être rempli", wilcard);
        case 'mandatoryField.edit.bt':
            return langWilcard("obligatoire", wilcard);
        case 'mandatoryField.edit.title':
            return langWilcard("tornar o campo obrigatório", wilcard);
        case 'showInTable.edit.bt':
            return langWilcard("afficher dans la grille", wilcard);
        case 'showInTable.edit.title':
            return langWilcard("afficher le champ sur la grille de résultats", wilcard);
        case 'fieldOptsBTEdit.edit.title':
            return langWilcard("options", wilcard);
        case 'dragDropFieldBT.edit.title':
            return langWilcard("changer de position", wilcard);
        case 'formRelationOneOpt.edit.placeholder':
            return langWilcard("informer l'instruction de remplissage", wilcard);
        case 'formRelationOneOpt.open.bt':
            return langWilcard("choisir le formulaire", wilcard);
        case 'formRelationOneOpt.edit.bt':
            return langWilcard("elegir formulario", wilcard);
        case 'formRelationOneOpt.screenNoName.lb':
            return langWilcard("formulaire sans nom", wilcard);
        case 'formRelationOneOpt.relationShipForms.title.lb':
            return langWilcard("formulaires à lister", wilcard);
        case 'formatSession.name':
            return langWilcard("disposition", wilcard);
        case 'formatOneColumn.name':
            return langWilcard("une colonne", wilcard);
        case 'formatTwoColumns.name':
            return langWilcard("deux colonnes", wilcard);
        case 'formatMobile.name':
            return langWilcard("portable", wilcard);
        case 'formTitle.edit.placeholder':
            return langWilcard("nom de forme", wilcard);
        case 'formAddField.edit.bt':
            return langWilcard("ajouter un autre champ", wilcard);
        case 'formNoId.edit.lb':
            return langWilcard("le link ne contient pas le id de formulaire", wilcard);
        case 'engine.useItem.bt':
            return langWilcard("utiliser l'élément sélectionné", wilcard);
        case 'panel.app.session':
            return langWilcard("application", wilcard);
        case 'panel.appName.lb':
            return langWilcard("un nom court et objectif", wilcard);
        case 'panel.appName.title':
            return langWilcard("utilisez un nom court et objectif. par exemple ventes, stock, budget", wilcard);
        case 'panel.newAppCreate.bt':
            return langWilcard("créer", wilcard);
        case 'panel.newApp.bt':
            return langWilcard("créer un nouveau", wilcard);
        case 'panel.newFolder.bt':
            return langWilcard("créer un dossier", wilcard);
        case 'panel.myApps.lb':
            return langWilcard("mes applications", wilcard);
        case 'props.app.title.lb':
            return langWilcard("nom", wilcard);
        case 'props.app.color.lb':
            return langWilcard("couleur", wilcard);
        case 'props.app.icon.lb':
            return langWilcard("icône", wilcard);
        case 'props.app.show.panel.lb':
            return langWilcard("montrer sur le mode d'exécution", wilcard);
        case 'props.app.search.icon.placeholder':
            return langWilcard("nom de l'icône (anglais)", wilcard);
        case 'props.general.mandatory.field':
            return langWilcard("champ obligatoire", wilcard);
        case 'new.app.unnamed.lb':
            return langWilcard("formulaire sans nom", wilcard);
        case 'app.edit.form.fields.bt':
            return langWilcard("les champs du formulaire", wilcard);
        default:
            return 'not recgnized key language';
    }
}