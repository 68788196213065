import React from "react";
import { Component } from "react";
import { lang } from '../Lang';
import { getDefaultServerUrl } from '../LinkBuilder';
import { PopupDialog } from '../common/PopupDialog';
import { AlertMessageInside } from '../common/AlertMessageInside';
import { PopupTemp } from '../common/PopupTemp';
import { SuccessMessageInside } from '../common/SuccessMessageInside';

export class FormsToRelation extends Component{
 
    constructor(props){
        super(props);

        this.state = {
            appList: [],
            formFieldList: [],
            selectedForm: '',
            noSelectedFormError: false,
            selectedFields: [],
            noSelectedFieldsError: false,
            showSuccessFormRelation: false
        };
    }

    componentDidMount(){

        if(this.props.json.relationFormOpt){

            this.setState({
                selectedForm: this.props.json.relationFormOpt,
                selectedFields: this.props.json.relationFormTitleFields.slice()
            });
        }

        this.getAllAppsAndForms();
    }
    
    getAllAppsAndForms(){

        //Load forms from server
        let customerIdUrl = 'custId='+localStorage.getItem('custId');
        let serverURL = getDefaultServerUrl('v1', 'getAllAppsForms', [], [customerIdUrl]);

        fetch(serverURL)
            .then(res => res.json())
            .then(
                (result) => {

                    this.setState({
                        appList: result,
                        openFormsToRelation: true
                    });

                    if(this.state.selectedForm !== ''){

                        this.getFormFieldsFromServer(this.state.selectedForm);
                    }

                    this.scrollToSelectedForm();

                    console.log("Forms load ok");
                },
                (error) => {
                    console.log("Forms load error");
                }
            );
    }

    scrollToSelectedForm(){

        if(this.state.selectedForm){
            document.getElementById(this.state.selectedForm).scrollIntoView();
        }
    }

    getFormFieldsFromServer(formId){

        //Load forms fields from server
        let formIdUrl = '/'+formId;
        let serverURL = getDefaultServerUrl('V1', 'getForm', [formIdUrl], []);

        fetch(serverURL)
            .then(res => res.json())
            .then(
                (result) => {

                    this.setState({
                        formFieldList: result
                    });

                    console.log("Forms field load ok");
                },
                (error) => {
                    console.log("Forms field load error");
                }
            );
    }

    linkChosenForm(event){
    
        if(this.state.selectedForm === ''){

            this.setState({
                noSelectedFormError: true
            });

            return;

        }else{

            this.setState({
                noSelectedFormError: false
            });
        }

        if(this.state.selectedFields.length === 0){

            this.setState({
                noSelectedFieldsError: true
            });

            return;
            
        }else{

            this.setState({
                noSelectedFieldsError: false
            });
        }

        //Link form
        let fieldIdUrl = '/'+this.props.json._id;
        let urlRelationFormIdUrl = 'relationFormId=' + this.state.selectedForm;
        let urlRelationFormTitleFieldsIdUrl = 'relationFormTitleFieldsId=' + this.state.selectedFields.toString();
        let serverURL = getDefaultServerUrl('V1', 'updateLinkRelatedForm', [fieldIdUrl], [urlRelationFormIdUrl, urlRelationFormTitleFieldsIdUrl]);

        fetch(serverURL)
            .then(res => res.json())
            .then(
                (result) => {

                    this.setState({
                        showSuccessFormRelation: true
                    });

                    this.props.json.relationFormOpt = this.state.selectedForm;
                    this.props.json.relationFormTitleFields = this.state.selectedFields;

                    console.log("Form link ok");
                },
                (error) => {
                    console.log("Form link error");
                }
            );
    }

    getFormFields(event){

        this.setState({
            selectedForm: event.target.value
        });

        this.getFormFieldsFromServer(event.target.value);
    }
        
    selectFormFields(event){

        let fieldCheckbox = event.target;

        if(fieldCheckbox.checked
            && !this.state.selectedFields.includes(fieldCheckbox.value)){

                this.setState({
                    selectedFields: this.state.selectedFields.concat(fieldCheckbox.value)
                });

        }else{

            const removeIndex = this.state.selectedFields.indexOf(fieldCheckbox.value);

            this.state.selectedFields.splice(removeIndex, 1);

            this.setState({
                selectedFields: this.state.selectedFields.splice(removeIndex, 1)
            });
        }

    }

    closePopupTimer(){

        this.setState({
            showSuccessFormRelation: false
        });

        this.props.parent.closeFieldsMenu();
    }

    closePopupDialog(){

        this.props.parent.closeFieldsMenu();
    }

    render(){
        return (
            <section>
                <PopupDialog parent={this} width='50%' title={lang('formRelationOneOpt.relationShipForms.title.lb')}>
                    
                    <section>

                        <AlertMessageInside isToShow={this.state.noSelectedFormError} msg='selecione um formulário'/>
                        
                        <div className='forms-relationship'>

                            {this.state.appList.map(app => (
                                
                                <div key={app._id} className='forms-relationship-item'>

                                    <div className='forms-relationship-app-name'>{app.appName}</div>
                                    
                                    {app.forms.map(form => (
                                            
                                        <div key={form._id} className='forms-relationship-form-name'> 
                                            <input id={form._id} type='radio' name='choose-form' value={form._id} onClick={this.getFormFields.bind(this)} defaultChecked={this.state.selectedForm === form._id ? true : false}/>
                                            <label className='radioLabel' htmlFor={form._id}>{form.name ? form.name : lang('formRelationOneOpt.screenNoName.lb')}</label>
                                        </div>
                                        
                                    ))}

                                    <button className='forms-relationship-new-form'/>
                                    
                                </div>
                            ))}

                        </div>
                        <div className='popup-dialog-content-sub-container'>configuração de como os dados do formulário escolhido aparecerão no campo</div>
                        <div className='forms-relationship-field-config-lb'>escolha os campos do formulário que serão utilizados:</div>

                        <AlertMessageInside isToShow={this.state.noSelectedFieldsError} msg='selecione pelo menos um campo do formulário'/>

                        <div className='forms-relationship-field-container'>
                            
                            {this.state.formFieldList.map(field => (
                                
                                <div key={field._id} className='forms-relationship-form-field-name'>
                                    <input id={field._id} type='checkbox' value={field._id} onClick={this.selectFormFields.bind(this)} defaultChecked={this.state.selectedFields.includes(field._id) ? true : false}/>
                                    <label className='checkLabel' htmlFor={field._id}>{field.title ? field.title : 'campo sem título'}</label>
                                </div>
                                
                            ))}

                        </div>

                        <button className='forms-relationship-do-relation-bt' onClick={this.linkChosenForm.bind(this)}>relacionar o formulário</button>

                    </section>

                </PopupDialog>
                

                {this.state.showSuccessFormRelation &&

                    <PopupTemp parent={this} width='30%' title='Sucesso' milesecondsTime='2000'>
                        <SuccessMessageInside isToShow='true' msg='formulário relacionado com sucesso!'/>
                    </PopupTemp>
                }
            </section>
        );
    }
}