import React from "react";
import { Component } from "react";
import { lang } from '../Lang'

export class DragDropFieldBTEdit extends Component {

    render() {
        return (
            <button className='field-move-bt' title={lang('dragDropFieldBT.edit.title')}/>
        );
    }
}
