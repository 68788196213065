import React from "react";
import { Component } from "react";
import { lang } from '../Lang';
import { setFieldDataCallback, syncFormData, getSyncFormData } from './SyncFormData';
import { getDefaultServerUrl } from '../LinkBuilder';

export class FormRelationOneOpt extends Component{
    
    
    constructor(props){
        super(props);

        var relationFormStart = {};
        var textStart = '';
        var valueIdStart = '';

        var formJson = getSyncFormData(this.props.stackFormId);
        if(formJson !== null){

            relationFormStart = formJson['_'+this.props.json._id];

            if (typeof relationFormStart == 'object') {

                valueIdStart = relationFormStart.valueId;
                textStart = relationFormStart.title;
            }
        }

        this.state = {
            relationFormObj:relationFormStart,
            valueId:valueIdStart,
            text:textStart,
            relationFormOpt:this.props.json.relationFormOpt,
            mandatoryError: 'field-required-off'
        };
    }

    componentDidMount(){

        var fieldUpdateCallback = () => {

            var formJson = getSyncFormData(this.props.stackFormId);
            
            if(formJson !== null
                && typeof formJson['_'+this.props.json._id] == 'object'){

                this.setState({
                    text: formJson['_'+this.props.json._id].title,
                    valueId: formJson['_'+this.props.json._id].valueId
                });

            }else{

                this.setState({
                    text: '',
                    valueId: ''
                });
            }
        }

        setFieldDataCallback(this.props.stackFormId, this.props.json._id, fieldUpdateCallback);

        this.setValueByUrlParam();
    }

    setValueByUrlParam(){

        //Try to get field data from url param
        if(this.props.json.apiFieldName){

            var paramValue = new URLSearchParams(window.location.search).get(this.props.json.apiFieldName);

            if(paramValue !== null
                && paramValue.trim() !== ''){

                //Get text from server
                let fieldIdUrl = 'fieldId='+this.props.json._id;
                let relationFormIdUrl = 'relationFormId=' + this.props.json.relationFormId;
                let serverURL = getDefaultServerUrl('v1', 'getRelatedFieldData', [], [fieldIdUrl, relationFormIdUrl]);

                fetch(serverURL)
                    .then(res => res.json())
                    .then(
                        (result) => {

                            var titleText='';



                            this.setState({
                                text: titleText,
                                valueId: paramValue
                            });

                            console.log("getRelatedFieldData ok");
                        },
                        (error) => {
                            console.log("getRelatedFieldData error");
                        }
                    );
            }
        }
    }

    handleChange(event) {
        
        this.setState({
            text: event.target.value,
            valueId: event.target.value
        });

        console.log('handleChange');


        //TODO: Show search box
    }

    handleBlur(event) {

        //Verify if is mandatory
        if(this.props.json.mandatory === 'field-required-on'
            && this.state.valueId.trim() === ''){

                this.setState({
                    mandatoryError: 'field-required-on'
                });

                return;
        }else{
            this.setState({
                mandatoryError: 'field-required-off'
            });
        }

        var formJson = getSyncFormData(this.props.stackFormId);
        if(formJson === null){
            formJson = {};
        }

        formJson['_'+this.props.json._id] = {'relationFormId':this.props.json.relationFormOpt,'valueId':this.state.valueId};

        syncFormData(this.props.stackFormId, formJson, 'noCallback');
    }

    handleFormRelationOneOptClick(event){

        //Open related form - Pass this object to set chosen option
        this.props.engineRun.addFormStack(this.props.json.relationFormOpt, this, this.props.json.relationFormTitleFields);


        //Send this object to stack and the openned stack form send selected value for the component
    }

    //Called by the related form when an item is selected or the same from search box
    updateChosenOpt(objId, objText){

        this.setState({
            text: objText,
            valueId: objId
        });

        this.handleBlur();
    }

    render(){

        return (
            <section>

                <div className='field-container'>
                    
                    <div className='field-top-container'>
                        <label className='field-name'>{this.props.json.title}</label>
                    </div>
                    
                    <div className='field-content-container'>
                        
                        <input type='hidden' value={this.state.valueId}/>
                        
                        <input className='field-text' type='text' value={this.state.text || ''} placeholder={this.props.json.placeholder} onChange={this.handleChange.bind(this)} onBlur={this.handleBlur.bind(this)}/>
                        
                        <div className='form-open-container'>
                            <button className='form-open-bt' onClick={this.handleFormRelationOneOptClick.bind(this)}>{lang('formRelationOneOpt.open.bt')}</button>
                        </div>

                        {this.state.mandatoryError === 'field-required-on' &&
                            <div className='field-error'>campo obrigatório</div>
                        }

                    </div>

                </div>

            </section>
        );
    }
}