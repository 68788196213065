import React from "react";
import './App.css';
import { EngineEdit } from './components/EngineEdit';
import { EngineRun } from './components/EngineRun';
import { Panel } from './components/Panel';

function App() {

  var editmode = new URLSearchParams(window.location.search).get("editmode");

  if(window.location.pathname === "/"){
    return (<Panel/>);
  }else if(editmode){
    return (<EngineEdit/>);
  }else{
    return (<EngineRun/>);
  }
}

export default App;
