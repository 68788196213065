import React from "react";
import { Component } from "react";
import { getDefaultServerUrl } from '../LinkBuilder';
import { lang } from '../Lang'

export class TitleFieldEdit extends Component {

    constructor(props){
        super(props);

        this.state = {
            value:this.props.json.title
        };
    }

    handleChange(event) {
        this.setState({
            value: event.target.value
        });
    }

    handleBlur(event) {

        let fieldIdUrl = 'maxCharacters=' + this.props.json._id;
        let titleUrl = 'title=' + this.state.value;
        let serverURL = getDefaultServerUrl('v1', 'updateFieldTitle', [], [fieldIdUrl, titleUrl]);

        console.log("URL=" + serverURL);

        fetch(serverURL)
            .then(res => res.json())
            .then(
                (result) => {
                    console.log("title update ok");
                },
                (error) => {
                    console.log("title update error");
                }
            );
    }

    render() {
        return (
            <input className='field-name' type='text' placeholder={lang('titleField.edit.placeholder')} value={this.state.value} onChange={this.handleChange.bind(this)} onBlur={this.handleBlur.bind(this)}/>
        );
    }
}