import React from "react";
import { Component } from "react";
import { PopupDialog } from '../common/PopupDialog';
import { RepresentativeComponent } from './RepresentativeComponent';

export class NewFormField extends Component{

    constructor(props){
        super(props);

        this.state = {
            fieldsMenuOpen:false
        };
    }

    openFieldsMenu(){

        this.setState({
            fieldsMenuOpen:true
        });
    }

    closeFieldsMenu(){

        this.setState({
            fieldsMenuOpen:false
        });
    }

    render(){

        return (
            <section>
                <div className='field-container'>
                    <div className='field-container-table'>
                        <div className='field-drag-drop'>
                            <div className='field-drag-drop-text'>
                                clique para selecionar o tipo de campo ou arraste um já existente
                            </div>
                            <div className='field-drag-drop-bt-container'>
                                <button className='field-drag-drop-bt' onClick={this.openFieldsMenu.bind(this)}>+</button>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.fieldsMenuOpen &&
                    <PopupDialog parent={this} width='50%' title='escolha o tipo de campo'>
                        
                        <section>
                            <div className='popup-section-title'>campos de texto</div>
                            <RepresentativeComponent name='Campo de texto curto' type='InputTextShort' slotKey={this.props.slotKey} engineEdit={this.props.engineEdit}/>
                        </section>

                        <section>
                            <div className='popup-section-title'>opção (possibilidade de relacionar com outros formulários)</div>
                            <RepresentativeComponent name='Campo de opção única' type='FormRelationOneOpt' slotKey={this.props.slotKey} engineEdit={this.props.engineEdit}/>
                        </section>

                    </PopupDialog>
                }
            </section>
        );
    }
}