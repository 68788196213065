import React from "react";
import { Component } from "react";
import { lang } from '../Lang';
import { FormDataTableCell } from './FormDataTableCell';
import { syncFormData } from './SyncFormData';

export class FormDataTableRows extends Component {

    handleClickLoad(rowJson){
        syncFormData(this.props.stackFormId, rowJson, 'allCallbacks');
    }

    render() {

        return (
            <tr>
                <td><button className='form-save-bt' onClick={this.handleClickLoad.bind(this, this.props.row)}>{lang('tablerow.edit.bt')}</button></td>
                
                {this.props.formFields.map(field => (
                   <FormDataTableCell key={field._id} row={this.props.row['_'+field._id]} stackFormId={this.props.stackFormId}/>
                ))}
            </tr>
        );
    }
}