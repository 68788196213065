import React from "react";
import { Component } from "react";
import { FormDataTableRows } from "./FormDataTableRows";

export class FormDataTable extends Component {

    render() {
        return (
            <table>
                <thead>
                    <tr>
                        <th>Ação</th>
                        {this.props.formFields.map(field => (
                            <th key={field._id}>{field.title}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {this.props.dataTable.map(row => (
                        <FormDataTableRows key={JSON.stringify(row)} formFields={this.props.formFields} row={row} stackFormId={this.props.stackFormId}/>
                    ))}
                </tbody>
            </table>
        );
    }
}