import React from "react";
import { Component } from "react";
import { setFieldDataCallback, syncFormData, getSyncFormData } from './SyncFormData';

export class InputTextShort extends Component{

    constructor(props){
        super(props);

        var initialValue = "";
        var formJson = getSyncFormData(this.props.stackFormId);
        if(formJson !== null){

            initialValue = formJson['_'+this.props.json._id];
        }

        this.state = {
            value:initialValue,
            mandatoryError: 'field-required-off'
        };
    }

    componentDidMount(){

        var fieldUpdateCallback = () => {

            //Get value from store
            var formJson = getSyncFormData(this.props.stackFormId);
            if(formJson !== null){

                this.setState({
                    value: formJson['_'+this.props.json._id]
                });

            }else{
                
                this.setState({
                    value: ''
                });
            }
        }

        setFieldDataCallback(this.props.stackFormId, this.props.json._id, fieldUpdateCallback);

        this.setValueByUrlParam();
    }

    setValueByUrlParam(){

        //Try to get field data from url param
        if(this.props.json.apiFieldName){

            var paramValue = new URLSearchParams(window.location.search).get(this.props.json.apiFieldName);

            if(paramValue !== null
                && paramValue.trim() !== ''){

                this.setState({
                    value: paramValue
                });
            }
        }
    }

    handleChange(event) {
        this.setState({
            value: event.target.value
        });
    }

    handleBlur(event) {

        //Verify if is mandatory
        if(this.props.json.mandatory === 'field-required-on'
            && this.state.value.trim() === ''){

                this.setState({
                    mandatoryError: 'field-required-on'
                });

                return;
        }else{
            this.setState({
                mandatoryError: 'field-required-off'
            });
        }


        var formJson = getSyncFormData(this.props.stackFormId);
        if(formJson === null){
            formJson = {};
        }

        formJson['_'+this.props.json._id] = this.state.value;

        syncFormData(this.props.stackFormId, formJson, 'noCallback');
    }

    render(){

        return (
            <div className='field-container'>
                
                <div className='field-top-container'>
                    <label className='field-name'>{this.props.json.title}</label>
                </div>
                
                <div className='field-content-container'>
                    <input id={this.props.json._id} className='field-text' type='text' maxLength={this.props.json.maxCharacters} value={this.state.value} placeholder={this.props.json.placeholder} onChange={this.handleChange.bind(this)} onBlur={this.handleBlur.bind(this)}/>
                </div>

                {this.state.mandatoryError === 'field-required-on' &&
                    <div className='field-error'>campo obrigatório</div>
                }
            </div>
        );
    }
}