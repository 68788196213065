import React from "react";
import { Component } from "react";

export class RepresentativeComponent extends Component{


    getFieldRender(renderType){
        switch (renderType) {
            case 'InputTextShort':
                
                return <input className='rep-field' type='text' readOnly></input>;

            case 'FormRelationOneOpt':
                
                return <div>
                    <input className='rep-field-with-bt' type='text' readOnly></input>
                    <button className='rep-field-bt'/>
                </div>;
                
        
            default:
                break;
        }
    }

    addNewField(event){

        this.props.engineEdit.setSlotField(this.props.slotKey, this.props.type);
    }

    render(){

        return (
            <section className='rep-field-container'>
                
                <div className='rep-field-container-bt'>
                    <button className='rep-field-add-bt' onClick={this.addNewField.bind(this)}>+</button>
                </div>
                
                <div className='rep-field-container-content'>
                    <div className='rep-field-title'>{this.props.name}</div>
                    {this.getFieldRender(this.props.type)}
                </div>
                
            </section>
        );
    }
}