import React from "react";
import { Component } from "react";
import { SwitchFieldEdit } from './edit/SwitchFieldEdit';
import { lang } from './Lang';
import { getDefaultServerUrl } from './LinkBuilder';

export class EngineEdit extends Component {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isLoaded: false,
            columnMode: 'field-slot-one-column',
            cellMode: 'field-slot-one-cell',
            fields: [],
            newSlotCount: 0
        };
    }

    componentDidMount() {

        var formIdUrl = 'formId=' + window.location.pathname;
        var serverURL = getDefaultServerUrl('v1', 'getForm', [], [formIdUrl]);

        fetch(serverURL)
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    isLoaded: true,
                    fields: result
                });
            },
            (error) => {
                this.setState({
                    isLoaded: true,
                    error
                });
            }
        );
    }

    changeToOneColumnMode(){

        this.setState({
            columnMode: 'field-slot-one-column',
            cellMode: 'field-slot-one-cell'
        });
    }

    changeToTwoColumnsMode(){
        
        this.setState({
            columnMode: 'field-slot-two-columns',
            cellMode: 'field-slot-two-cell'
        });
    }

    changeToMobileMode(){
        
        this.setState({
            columnMode: 'field-slot-mobile',
            cellMode: 'field-slot-one-cell'
        });
    }
    
    addMoreField(){

        var idKey = 'newSlot' + this.state.newSlotCount;

        var fieldsToPush = this.state.fields;
        fieldsToPush.push({_id: idKey, type: 'NewFormField'});

        this.setState({
            newSlotCount: this.state.newSlotCount + 1,
            fields: fieldsToPush
        });
    }

    setSlotField(slotKey, fieldType){

        var slotIndex = -1;
        for (let i = 0; i < this.state.fields.length; i++) {
            
            if(this.state.fields[i]._id === slotKey){

                slotIndex = i;
                break;
            }
        }

        //Send new field to server

        let formIdUrl = 'formId=' + window.location.pathname.replace('/','');
        let fieldTypeUrl = 'fieldType=' + fieldType;
        let serverURL = getDefaultServerUrl('v1', 'putField', [], [formIdUrl, fieldTypeUrl]);

        console.log("URL=" + serverURL);

        fetch(serverURL)
            .then(res => res.json())
            .then(
                (result) => {

                    var fields = this.state.fields;

                    //Remove new field slot from collection
                    fields.splice(slotIndex, 1, result);

                    this.setState({
                        fields: fields
                    });
                },
                (error) => {
                    console.log("put new field error");
                }
            );
    }

    render() {
        if(window.location.pathname === "/"){
            return lang('formNoId.edit.lb');
        }

        return (
            <section className='height-max'>
                <div className='left-bar'>
                    <section>
                    <div className='logo'/>
                        <span className='logo-name'>{lang('trade.mark')}</span>
                    </section>
                    <section>
                        <span className='session-format-edit'>{lang('formatSession.name')}</span>
                        <button className='session-format-one-column-edit' onClick={this.changeToOneColumnMode.bind(this)}>{lang('formatOneColumn.name')}</button>
                        <button className='session-format-two-columns-edit' onClick={this.changeToTwoColumnsMode.bind(this)}>{lang('formatTwoColumns.name')}</button>
                        <button className='session-format-mobile-edit' onClick={this.changeToMobileMode.bind(this)}>{lang('formatMobile.name')}</button>
                    </section>
                </div>
                <div className='content'>
                    
                    <div className={this.state.columnMode}>
                        <div className='breadcrump'>empresa x &gt; painel &gt; comercial &gt; clientes &gt; cadastro</div>
                        <div className='form-name-edit'><input className='form-name-field' type='text' placeholder={lang('formTitle.edit.placeholder')}/></div>
                        <div className='fields-scroll'>
                            {this.state.fields.map(field => (
                                <div key={field._id+'_cell'} className={this.state.cellMode}>
                                    <SwitchFieldEdit key={field._id} json={field} engineEdit={this}/>
                                </div>
                            ))}
                            <div className='full-line'>
                                <button className='add-more-fields-edit' onClick={this.addMoreField.bind(this)}>{lang('formAddField.edit.bt')}</button>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        );
    }
}