import React from "react";
import { Component } from "react";

export class AlertMessageInside extends Component{

    render(){
        return (
            <section className={this.props.isToShow ? '' : 'component-hidden'}>
                <div className='alert-msg-inside'>
                    <label>{this.props.msg}</label>
                </div>
            </section>
        );
    }
}