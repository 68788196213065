import React from "react";
import { Component } from "react";
import { FormDataTable } from "./FormDataTable";
import { SwitchField } from './SwitchField';
import { lang } from '../Lang'
import { getSyncFormData, removeSyncFormData, getSyncFormDataNoParse } from './SyncFormData';
import { getDefaultServerUrl } from '../LinkBuilder' 

export class FormStackRun extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
            error: null,
            fields: [],
            dataTable: []
        };
    }

    componentDidMount(){

        this.loadForm();
    }

    loadForm(){

        let formIdUrl = 'formId='+this.props.stackFormId;
        let serverURL = getDefaultServerUrl('v1', 'getForm', [], [formIdUrl]);

        fetch(serverURL)
        .then(res => res.json())
        .then(
            (result) => {

                this.setState({
                    fields: result
                });

                this.loadDataTable();
            },
            (error) => {
                this.setState({
                    error
                });
            }
        );
    }

    loadDataTable(){

        let formIdUrl = 'formId='+this.props.stackFormId;
        let serverURL = getDefaultServerUrl('v1', 'getFormData', [], [formIdUrl]);

        fetch(serverURL)
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    dataTable: result
                });
            },
            (error) => {
                this.setState({
                    error
                });
            }
        );
    }

    handleClickSave(event){

        var formJson = getSyncFormData(this.props.stackFormId);

        if(formJson._id == null){
            console.log("save");
            this.saveNew(event);
        }else{
            console.log("update");
            this.update(event);
        }
    }

    saveNew(event){

        if(getSyncFormData(this.props.stackFormId) !== null){

            let formIdUrl = 'formId='+this.props.stackFormId;
            let serverURL = getDefaultServerUrl('v1', 'createFormData', [], [formIdUrl]);

            fetch(serverURL, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: getSyncFormDataNoParse(this.props.stackFormId)})
                //body: sessionStorage.getItem(this.props.stackFormId)})
            .then(res => res.json())
            .then(
                (result) => {
                    console.log("create form object");
                    this.loadDataTable();
                    this.clearForm();
                },
                (error) => {
                    console.log("error to create form object");
                }
            );
        }
    }

    update(event){

        if(getSyncFormData(this.props.stackFormId) !== null){

            let formIdUrl = 'formId='+this.props.stackFormId;
            let serverURL = getDefaultServerUrl('v1', 'updateFormData', [], [formIdUrl]);

            fetch(serverURL, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: getSyncFormDataNoParse(this.props.stackFormId)})
            .then(res => res.json())
            .then(
                (result) => {
                    console.log("updated form object");
                    this.loadDataTable();
                    this.clearForm();
                },
                (error) => {
                    console.log("error to update form object");
                }
            );
        }
    }

    handleClickRemove(event){

        if(getSyncFormData(this.props.stackFormId) !== null){

            let formIdUrl = 'formId='+this.props.stackFormId;
            let serverURL = getDefaultServerUrl('v1', 'removeFormData', [], [formIdUrl]);

            fetch(serverURL, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: getSyncFormDataNoParse(this.props.stackFormId)})
            .then(res => res.json())
            .then(
                (result) => {
                    console.log("deleted form object");
                    this.loadDataTable();
                    this.clearForm();
                },
                (error) => {
                    console.log("error to delete form object");
                }
            );
        }
    }

    handleClickClearForm(event){
        this.clearForm();
    }

    clearForm(){
        removeSyncFormData(this.props.stackFormId, 'allCallbacks');
    }

    setSelectedToRelatedForm(){

        if(getSyncFormData(this.props.stackFormId) !== null){

            var formJson = getSyncFormData(this.props.stackFormId);

            var title = '';
            for(var i=0; i < this.props.fieldsIdsToTitle.length; i++){
                title += ' - ';
                title += formJson['_'+this.props.fieldsIdsToTitle[i]];
            }

            title = title.replace(' - ', '');

            this.props.relatedObj.updateChosenOpt(formJson._id, title);

            this.props.engineRun.backToLastForm();
        }
    }

    handleClickCloseStackBT(event){
        this.props.engineRun.backToLastForm();
    }

    render() {

        if(window.location.pathname === "/"){
            return lang('formNoId.edit.lb');
        }

        return (
            <section>

                <div className='form-title-container'>
                    <div className='form-title'>Form name on breadcrumb format</div>

                    {this.props.isNewFormStack &&
                        <button className='form-close-stack-bt' onClick={this.handleClickCloseStackBT.bind(this)}></button>
                    }
                </div>

                <div className='field-slot-one-column'>
                    {this.state.fields.map(field => (
                        <SwitchField key={field._id} json={field} engineRun={this.props.engineRun} stackFormId={this.props.stackFormId}/>
                    ))}
                </div>

                <div className='form-tool-bar'>
                    <button className='form-save-bt' onClick={this.handleClickSave.bind(this)}>{lang('engine.save.bt')}</button>
                    <button className='form-save-bt' onClick={this.handleClickRemove.bind(this)}>{lang('engine.remove.bt')}</button>
                    <button className='form-save-bt' onClick={this.handleClickClearForm.bind(this)}>{lang('engine.clear.bt')}</button>

                    {this.props.isNewFormStack &&
                        <button className='form-save-bt' onClick={this.setSelectedToRelatedForm.bind(this)}>{lang('engine.useItem.bt')}</button>
                    }
                </div>

                <div className='form-table-container'>
                    <FormDataTable formFields={this.state.fields} dataTable={this.state.dataTable} stackFormId={this.props.stackFormId}/>
                </div>

            </section>
        );
    }
}