var callbackFunctionMap = new Map();

/**
 * Synchronize form data with browser store and run update field callbacks if ordered.
 * - fieldId is the id key that came from server
 * - jsonObj is the form json object
 * - callbackRunMode is to inform to run thefield's callback, 'allCallbacks' to run all callbacks on list or 'noCallback' to not run any one
 */
export function syncFormData(formId, jsonObj, callbackRunMode) {

    sessionStorage.setItem(formId, JSON.stringify(jsonObj));

    runCallbackList(formId, callbackRunMode);
}

function runCallbackList(formId, callbackRunMode){

    if(callbackRunMode === 'noCallback'){

        return;
    }

    for (const [formFieldKey, callbackFunction] of callbackFunctionMap.entries()) {

        //Call callbacks functions from informed formId. 
        if(formFieldKey.startsWith(formId)){

            setTimeout(callbackFunction(), 0);
        }
    }
}

export function getSyncFormData(formId) {

    if(sessionStorage.getItem(formId) !== null){

        return JSON.parse(sessionStorage.getItem(formId));
    }

    return null;
}

export function getSyncFormDataNoParse(formId) {

    return sessionStorage.getItem(formId);
}

export function setFieldDataCallback(formId, fieldId, callbackFunction) {

    var formFieldKey = formId + '_' + fieldId;

    callbackFunctionMap.set(formFieldKey, callbackFunction);
}

/**
 * Remove synchronized form data with browser store and run update field callbacks if ordered.
 * - fieldId is the id key that came from server
 * - callbackRunMode is to inform to run thefield's callback, 'allCallbacks' to run all callbacks on list or 'noCallback' to not run any one
 */
export function removeSyncFormData(formId, callbackRunMode) {

    if(sessionStorage.getItem(formId) !== null){

        sessionStorage.removeItem(formId);

        runCallbackList(formId, callbackRunMode);
    }
}


/*
Stored code for the future if it is needed

function sendStorageChangeEvent() {

    console.log('sendStorageChangeEvent');

    const iframeEl = document.createElement("iframe");
    iframeEl.style.display = "none";
    document.body.appendChild(iframeEl);
  
    iframeEl.contentWindow?.sessionStorage.setItem("t", Date.now().toString());
    iframeEl.remove();
}
window.onstorage = () => {

    for (const entry of callbackFunctionMap.entries()) {

        //Call callback function
        entry[1]();
    }
};*/