import React from "react";
import { Component } from "react";
import { InputTextShortEdit } from './InputTextShortEdit';
import { FormRelationOneOptEdit } from './FormRelationOneOptEdit';
import { NewFormField } from './NewFormField'

export class SwitchFieldEdit extends Component {

    render() {
        switch (this.props.json.type) {
            case 'InputTextShort':
                return <InputTextShortEdit json={this.props.json}/>

            case 'FormRelationOneOpt':
                return <FormRelationOneOptEdit json={this.props.json}/>

            case 'NewFormField':
                return <NewFormField slotKey={this.props.json._id} engineEdit={this.props.engineEdit}/>
                
            default:
                return <div>No recognized field</div>
        }
    }
}