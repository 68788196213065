import React from "react";
import { Component } from "react";
import { getDefaultServerUrl } from '../LinkBuilder';
import { lang } from '../Lang'
import { ColorPicker } from "../common/ColorPicker";
import { IconPicker } from "../common/IconPicker";

export class AppCardProperties extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            appName: this.props.json.appName,
            showOnPanel: this.props.json.showOnPanel,
            mandatoryNameError: false
        };
    }

    handleAppNameChange(event){

        this.setState({
            appName: event.target.value
        });
    }

    handleAppNameChangeComplete(event){

        if(this.state.appName.trim() === ''){

            this.setState({
                mandatoryNameError: true
            });

            return;
        }

        this.props.appCard.setNewName(event.target.value);

        //Send new name to server
        let appIdUrl = 'appId=' + this.props.json._id;
        let nameUrl = 'name=' + this.state.appName;
        let serverURL = getDefaultServerUrl('v1', 'updateAppName', [], [appIdUrl, nameUrl]);

        fetch(serverURL)
        .then(res => res.json())
        .then(
            (result) => {

                console.log("change app name success");

                this.setState({
                    mandatoryNameError: false
                });
            },
            (error) => {
                console.log("change app name error");
            }
        );
    }

    handleSelectColor(color){

        this.setState({
            showColorPicker: false,
            cardColor: color
        });

        this.props.appCard.setNewColor(color);

        //Send new color to server
        let appIdUrl = 'appId=' + this.props.json._id;
        let colorUrl = 'color=' + color.replace('#','');
        let serverURL = getDefaultServerUrl('v1', 'updateAppCardColor', [], [appIdUrl, colorUrl]);

        fetch(serverURL)
        .then(res => res.json())
        .then(
            (result) => {
                console.log("change app card color success");
            },
            (error) => {
                console.log("change app card color error");
            }
        );
    }

    handleSelectIcon(icon){

        this.setState({
            showColorPicker: false,
            cardIcon: icon
        });

        this.props.appCard.setNewIcon(icon);

        //Send new color to server
        let appIdUrl = 'appId=' + this.props.json._id;
        let iconUrl = 'icon=' + icon;
        let serverURL = getDefaultServerUrl('v1', 'updateAppCardIcon', [], [appIdUrl, iconUrl]);

        fetch(serverURL)
        .then(res => res.json())
        .then(
            (result) => {
                console.log("change app card icon success");
            },
            (error) => {
                console.log("change app card icon error");
            }
        );
    }

    handleAppShowOnPanelChange(event){

        this.setState({
            showOnPanel: event.target.checked,
        });

        this.props.appCard.setShowOnPanel(event.target.checked);

        //Send show on panel to server
        let appIdUrl = 'appId=' + this.props.json._id;
        let showOnPanelUrl = 'showOnPanel=' + event.target.checked;
        let serverURL = getDefaultServerUrl('v1', 'updateAppShowOnPanel', [], [appIdUrl, showOnPanelUrl]);

        fetch(serverURL)
        .then(res => res.json())
        .then(
            (result) => {
                console.log("change app card show on panel success");
            },
            (error) => {
                console.log("change app card show on panel error");
            }
        );
    }

    editFormFields(event){
        this.props.appCard.runApp();
    }

    render() {
        return (
            <section>

                <div className='props-title'>{lang('props.app.title.lb')}</div>
                <div className='props-field-large-container'>

                    <input className='field-text-system' type='text' value={this.state.appName} placeholder={lang('panel.appName.placeholder')} title={lang('panel.appName.title')} onChange={this.handleAppNameChange.bind(this)} onBlur={this.handleAppNameChangeComplete.bind(this)}/>
                
                    {this.state.mandatoryNameError &&
                        <div className='field-error'>{lang('props.general.mandatory.field')}</div>
                    }

                </div>

                <button className="props-app-edit-fields" onClick={this.editFormFields.bind(this)}>{lang('app.edit.form.fields.bt')}</button>

                <div className='props-title'>{lang('props.app.show.panel.lb')}</div>
                <div className='props-field-large-container'>
                    <input className='field-text-system' type='checkbox' checked={this.state.showOnPanel} onChange={this.handleAppShowOnPanelChange.bind(this)}/>
                </div>

                <div className='props-title'>{lang('props.app.color.lb')}</div>
                <div className='props-field-large-container'>
                    <ColorPicker onSelectColor={this.handleSelectColor.bind(this)}/>
                </div>

                <div className='props-title'>{lang('props.app.icon.lb')}</div>
                <div className='props-field-large-container'>
                    <IconPicker onSelectColor={this.handleSelectIcon.bind(this)}/>
                </div>

                {/**TODO: Botão para remover o formulário */}

            </section>
        );
    }
}