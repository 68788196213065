import React from "react";
import { Component } from "react";
import { DragDropFieldBTEdit } from "./DragDropFieldBTEdit";
import { FieldOptsBTEdit } from "./FieldOptsBTEdit";
import { TitleFieldEdit } from "./TitleFieldEdit";
import { ShowInTableBTEdit } from "./ShowInTableBTEdit";
import { MandatoryFieldBTEdit } from "./MandatoryFieldBTEdit";
import { lang } from '../Lang';
import { getDefaultServerUrl } from '../LinkBuilder';

export class InputTextShortEdit extends Component{
 
    constructor(props){
        super(props);

        this.state = {
            value:this.props.json.placeholder
        };
    }

    handleChange(event) {
        this.setState({
            value: event.target.value
        });
    }

    handleBlur(event) {

        let fieldIdUrl = 'fieldId='+this.props.json._id;
        let placeholderUrl = 'placeholder=' + this.state.value;
        let serverURL = getDefaultServerUrl('v1', 'updateFieldPlaceholder', [], [fieldIdUrl, placeholderUrl]);

        fetch(serverURL)
            .then(res => res.json())
            .then(
                (result) => {
                    console.log("Placeholder update ok");
                },
                (error) => {
                    console.log("Placeholder update error");
                }
            );
    }

    render(){
        return (
            <div className='field-container'>
                <div className='field-top-container'>
                    <TitleFieldEdit json={this.props.json}/>
                    <FieldOptsBTEdit json={this.props.json}/>
                    <DragDropFieldBTEdit json={this.props.json}/>
                </div>
                <div className='field-content-container'>
                    <input id={this.props.json._id} className='field-text' type='text' placeholder={lang('inputTextShort.edit.placeholder')} value={this.state.value} onChange={this.handleChange.bind(this)} onBlur={this.handleBlur.bind(this)}/>
                </div>
                <div className='field-botton'>
                    <MandatoryFieldBTEdit json={this.props.json}/>
                    <ShowInTableBTEdit json={this.props.json}/>
                </div>
            </div>
        );
    }
}