import React from "react";
import { Component } from "react";
import { getDefaultViewUrl } from '../LinkBuilder';

export class AppCard extends Component{

    constructor(props) {
        super(props);
    
        let cardIconInit = 'crop_free';
        if(this.props.json.cardIcon){
            cardIconInit = this.props.json.cardIcon;
        }

        this.state = {
            cardColor: '#' + this.props.json.cardColor,
            cardIcon: cardIconInit,
            appName: this.props.json.appName
        };
    }

    componentDidMount() {
        this.props.panel.setEditionCardInstance(this, this.props.json);
    }

    runApp(event){

        var formRoute = '/' + this.props.json.firstFormId

        let editMode = (this.props.editionMode ? 'editmode=true' : '');

        window.location.assign(getDefaultViewUrl([formRoute], [editMode]));
    }

    setNewColor(color){

        this.setState({
            cardColor: color
        });

        this.props.json.cardColor = color;
    }

    setNewName(name){

        this.setState({
            appName: name
        });

        this.props.json.appName = name;
    }

    setNewIcon(icon){

        this.setState({
            cardIcon: icon
        });

        this.props.json.cardIcon = icon;
    }

    setShowOnPanel(showOnPanel){

        this.props.json.showOnPanel = showOnPanel;
    }

    getTextColor(backgroundColor){

        if(backgroundColor === '#B80000'
            || backgroundColor === '#DB3E00'
            || backgroundColor === '#008B02'
            || backgroundColor === '#006B76'
            || backgroundColor === '#1273DE'
            || backgroundColor === '#004DCF'){

            return "#ffffff";
        }

        return "#2e2f31";
    }

    getIconColor(backgroundColor){

        if(backgroundColor === '#B80000'
            || backgroundColor === '#DB3E00'
            || backgroundColor === '#008B02'
            || backgroundColor === '#006B76'
            || backgroundColor === '#1273DE'
            || backgroundColor === '#004DCF'){

            return "material-icons md-36 md-light";
        }

        return "material-icons md-36 md-dark";
    }

    openProperties(){

        this.setState({
            showQuickOptions: !this.state.showQuickOptions
        });

        this.props.panel.openPropertiesMode('AppCardProperties', this, this.props.json);
    }

    render(){

        return (
            <section className='app-card-container'>

                <button className='app-card-play-bt' style={{backgroundColor:this.state.cardColor, color:this.getTextColor(this.state.cardColor)}} onClick={this.runApp.bind(this)}>
                    <span className={this.getIconColor(this.state.cardColor)}>{this.state.cardIcon}</span>
                    <br/>
                    <span>{this.state.appName}</span>
                </button>

                {this.props.editionMode &&
                    <div className="app-card-quick-tools">
                        <button className='app-card-edit-bt' onClick={this.openProperties.bind(this)}/>
                    </div>
                }
            </section>
        );
    }
}