import React from "react";
import { Component } from "react";

export class FormDataTableCell extends Component {

    render() {

        if(typeof this.props.row === 'object'){

            return (
                <td>{this.props.row.title}</td>
             );

        }else{

            return (
                <td>{this.props.row}</td>
             );
        }
    }
}