import React from "react";
import { Component } from "react";
import { lang } from '../Lang';
import { getDefaultServerUrl } from '../LinkBuilder';

export class ShowInTableBTEdit extends Component {

    constructor(props){
        super(props);

        this.state = {
            status:this.props.json.showInTable
        };
    }

    handleClick(event){

        var newStatus = "field-use-in-table-on";
        if(this.state.status === "field-use-in-table-on"){
            newStatus = "field-use-in-table-off";
        }

        let fieldIdUrl = 'field='+this.props.json._id;
        let statusUrl = 'status=' + newStatus;
        let serverURL = getDefaultServerUrl('v1', 'updateFieldShowInTable', [], [fieldIdUrl, statusUrl]);

        fetch(serverURL)
            .then(res => res.json())
            .then(
                (result) => {
                    if(this.state.status === "field-use-in-table-on"){
                        this.setState({
                            status: "field-use-in-table-off"
                        });
                    }else{
                        this.setState({
                            status: "field-use-in-table-on"
                        });
                    }
                    console.log("show in table update ok");
                },
                (error) => {
                    console.log("show in table update error");
                }
            );
    }

    render() {
        return (
            <div className='field-botton'>
                <button className={this.state.status} title={lang('showInTable.edit.title')} onClick={this.handleClick.bind(this)}>{lang('showInTable.edit.bt')}</button>
            </div>
        );
    }
}