import React from "react";
import { Component } from "react";
import { InputTextShort } from './InputTextShort';
import { FormRelationOneOpt } from './FormRelationOneOpt';

export class SwitchField extends Component {

    render() {
        switch (this.props.json.type) {
            case 'InputTextShort':
                return <InputTextShort json={this.props.json} engineRun={this.props.engineRun} stackFormId={this.props.stackFormId}/>

            case 'FormRelationOneOpt':
                return <FormRelationOneOpt json={this.props.json} engineRun={this.props.engineRun} stackFormId={this.props.stackFormId}/>

            default:
                return <div>No recognized field</div>
        }
    }
}